import { createSlice } from '@reduxjs/toolkit';



export const businessSlice = createSlice({
  name: 'business',
  initialState: {businessProfile:null},
  reducers: {
      setBusiness: (state, action) => {
        state.businessProfile = action.payload;
      },
  },
})



export const { setBusiness } = businessSlice.actions

export default businessSlice.reducer
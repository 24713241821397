import { createSlice } from '@reduxjs/toolkit';



export const premiumSlice = createSlice({
  name: 'premium',
  initialState: {Premium:null},
  reducers: {
      setPremium: (state, action) => {
        state.Premium = action.payload;
      },
  },
})



export const { setPremium } = premiumSlice.actions

export default premiumSlice.reducer
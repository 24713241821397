import { createSlice } from '@reduxjs/toolkit';



export const notifySlice = createSlice({
  name: 'notify',
  initialState: {notifications:[]},
  reducers: {
      setNotify: (state, action) => {
        state.notifications = action.payload;
        localStorage.setItem('notifications', JSON.stringify(action.payload));
      },
  },
})



export const { setNotify } = notifySlice.actions

export default notifySlice.reducer
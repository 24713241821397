import { createSlice } from '@reduxjs/toolkit';



export const activeSlice = createSlice({
  name: 'post',
  initialState: {Active:false},
  reducers: {
    setActive: (state, action) => {
        state.Active = action.payload;
      },
  },
})



export const { setActive } = activeSlice.actions

export default activeSlice.reducer
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const nav = useNavigate();

  useEffect(() => {
    const modalElement = document.getElementById('staticBackdrop');
    const modal = new window.bootstrap.Modal(modalElement, {
      backdrop: 'static',
      keyboard: false
    });
    modal.show();
  }, []);

  const handleGoBack = () => {
    nav(-1);
  };

  return (
    <div className='container'>
      <div className="modal  show notfoundPage" id="staticBackdrop" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content" style={{border:"none"}}>
            <div className="modal-body">
              <div className='not-found-content'>
                <img src="/image/errorPayment.png" alt="Not Found" />
                <h2>Whoops... Page Not Found :(</h2>
                <button className='nav-button' onClick={handleGoBack}>Go Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const Private = () => {
    var auth = localStorage.getItem("token")
  return (
    <>
    {
      auth
        ?
        <Outlet />
        :
        <Navigate to='/sign-in-account'></Navigate>
    }
    </>
    
  )
}

export default Private
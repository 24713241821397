import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  email: "",
  name: "",
  profilePic: "",
  points:0,
  _id:""  
}


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
      setUserInfo: (state, action) => {
          return {
              ...state,
              ...action.payload
          }
      },
  },
})



export const { setUserInfo } = userSlice.actions

export default userSlice.reducer










// import { createSlice } from '@reduxjs/toolkit';



// export const userSlice = createSlice({
//   name: 'user',
//   initialState: {userProfile:null},
//   reducers: {
//       setUserInfo: (state, action) => {
//         state.userProfile = action.payload;
//       },
//   },
// })



// export const { setUserInfo } = userSlice.actions

// export default userSlice.reducer
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../Api';
import { toast } from 'react-hot-toast';

const CheckoutLimit= () => {
  const stripe = useStripe();
  const elements = useElements();
  const nav = useNavigate()
  const [error, setError] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: 'http://208.68.37.50:3011/limit-status',
        },

      });

      if (error) {
        setError(true);
        toast.error(error.message)
      }
    } catch (error) {
      console.error(error.message);
    }
  };
  const nextStep = () => {
    localStorage.removeItem('chat_limits')
    nav('/community', { state: 'ask-our-ai' })
  }

  return (
    <div>
      <div style={{ height: '100vh' }}>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: 'auto', marginTop: '50px' }}>
                  <img src="/image/payments.png" className="paymentImage"  alt="paymentImage" />
                </div>
              </div>
              <div className='col-xs-12 col-sm-12 col-md-6 col-lg-6'>
                <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: 'auto', marginTop: '50px' }}>
                  <form onSubmit={handleSubmit}>
                    <PaymentElement />
                    <button type="submit" className='nav-button' style={{ marginTop: '10px', marginBottom: '10px' }} disabled={!stripe}>
                      Submit
                    </button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="submit" onClick={nextStep} className='nav-button' style={{ marginTop: '10px', marginBottom: '10px' }} disabled={!stripe}>
                      Cancel
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default CheckoutLimit;

import React from 'react'
import BarLoader from "react-spinners/BarLoader"

const HomeLazy = () => {
  return (
    <div style={{ height: '100vh',flexDirection:"column",gap:"10px", display: "flex", justifyContent: 'flex-start',paddingTop:'230px', alignItems: "center" }}>
      <img src='/image/load-Carfo.png' style={{width:"130px"}}/>
      <BarLoader
        color="#8a00b7"
        height={2}
        speedMultiplier={1.5}
        width={200}
      />
    </div>
  )
}

export default HomeLazy

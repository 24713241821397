import React from 'react'

const Loading = () => {
  return (
    <div className='loading'>
      <img src='/image/loading.gif' alt="loading..."/>
      <p>Switching profile...</p>
    </div>
  )
}

export default Loading

const BASE_URL = process.env.REACT_APP_BASE_URL

const API = {
    DRAFT_REGISTER: BASE_URL + 'api/v1/user/draft_register',
    RESEND_OTP: BASE_URL + 'api/v1/user/resend_otp',
    REGISTER: BASE_URL + 'api/v1/user/register',
    LOGIN: BASE_URL + 'api/v1/user/login',
    SOCIAL_LOGIN: BASE_URL + 'api/v1/user/social_login',
    FORGOT_PASSWORD: BASE_URL + 'api/v1/user/forgot_password',
    VERIFY_OTP: BASE_URL + 'api/v1/user/verify_otp',
    RESET_PASSWORD: BASE_URL + 'api/v1/user/reset_password',
    LOGOUT: BASE_URL + 'api/v1/user/logout',
    CHANGE_PASSWORD: BASE_URL + 'api/v1/user/change_password',
    USER_DETAILS: BASE_URL + 'api/v1/user/user_details',
    EDIT_USER: BASE_URL + 'api/v1/user/edit_user_details',
    CONTACT_US: BASE_URL + 'api/v1/user/contact_us',

    // version 2 api's 
    EDIT_USER_BG_BANNER : BASE_URL + 'api/v2/user/update_user_banner_image', 
    EDIT_USER_PROFILE_PIC : BASE_URL + 'api/v2/user/update_user_profile_image', 
    GET_OTHER_USER_PROFILE : BASE_URL + 'api/v2/user/user_profile', 
    REPORT_USER_PROFILE: BASE_URL +'api/v2/user/report_user_profile', 
    FOLLOW_UNFOLLOW_USER_PROFILE : BASE_URL + 'api/v2/user/follow_unfollow_user_profile', 
    MIGHT_ALSO_FOLLOW: BASE_URL + 'api/v2/user/also_follow',

    //notify
    GET_NOTIFICATIONS: BASE_URL + 'api/v1/user/get_notifications',

    //buy membership
    BUY_MEMBERSHIP: BASE_URL + 'api/v1/user/buy_membership',
    GET_PAYMENT_HISTORY: BASE_URL + 'api/v1/user/get_payment_history',
    SAVE_PAYMENT: BASE_URL + 'api/v1/user/save_purchase',

    CREATE_PAYMENT: BASE_URL + 'api/v1/user/create_payment',
    CREATE_PAYMENT_FEATURED: BASE_URL + 'api/v1/user/create_payment_featured_post',

    //Banner one ads
    GET_ONE_ADS: BASE_URL + 'api/v1/user/get_one_banner',

    //articles
    GET_ARTICLES: BASE_URL + 'api/v1/user/get_all_article',
    GET_DEFAULT_ARTICLE: BASE_URL + 'api/v1/user/default_article',
    GET_RELATED_TOPIC: BASE_URL + 'api/v1/user/get_related_topic',
    MORE_LIKE_THIS: BASE_URL + 'api/v1/user/more_like_this',
    GET_ARTICLE_DETAILS: BASE_URL + 'api/v1/user/get_article_details/',
    GET_TOPICS: BASE_URL + 'api/v1/user/all_topic',
    GET_FAQ: BASE_URL + 'api/v1/user/all_faq',
    GET_POP_TOPIC: BASE_URL + 'api/v1/user/get_popular_topic',

    // feeds section
    GET_FEEDS_POST: BASE_URL + 'api/v1/user/get_all_post',
    GET_FEEDS_DETAILS: BASE_URL + 'api/v1/user/get_post_detail/',
    REPORT_POST: BASE_URL + 'api/v1/user/report_post/',
    COMMENT_POST: BASE_URL + 'api/v1/user/comment_post/',
    COMMENT_REPORT: BASE_URL + 'api/v1/user/comment_report/',
    COMMENT_DELETE: BASE_URL + 'api/v1/user/comment_delete/',


    //post section
    CREATE_POST: BASE_URL + 'api/v1/user/create_post',
    CREATE_FEATURED_POST: BASE_URL + 'api/v1/user/create_featured_post',
    SAVE_POST: BASE_URL + 'api/v1/user/save_wait_post',
    USER_POST: BASE_URL + 'api/v1/user/get_user_post',
    USER_POST_DETAILS: BASE_URL + 'api/v1/user/get_user_post_details/',
    DELETE_POST: BASE_URL + 'api/v1/user/delete_post/',
    POST_DETAIL: BASE_URL + 'api/v1/user/get_single_post_details/',
    EDIT_POST: BASE_URL + 'api/v1/user/edit_post/',

    //post details section
    GET_POST_DETAIL: BASE_URL + 'api/v1/user/get_post_details',
    GET_SUGGESTED_POST: BASE_URL + 'api/v1/user/get_suggested_post',

    //leaders
    GET_LEADERS: BASE_URL + 'api/v1/user/get_top_leaders',

    //content section
    GET_PRIVACY: BASE_URL + 'api/v1/user/privacy',
    GET_TERMS: BASE_URL + 'api/v1/user/terms',

    // FEATURED
    GET_FEATURED_POST: BASE_URL + 'api/v1/user/get_featured_post',
    GET_POPULAR_POST: BASE_URL + 'api/v1/user/get_popular_post',

    //Banner section
    CREATE_NEW_BANNER: BASE_URL + 'api/v1/user/create_banner',
    UPDATE_STATUS_SUCCESS : BASE_URL + 'api/v1/user/update_banner_status_on_success',
    SAVE_BANNER_DRAFT: BASE_URL + 'api/v1/user/save_banner_draft',
    DELETE_BANNER_DRAFT: BASE_URL + 'api/v1/user/delete_banner_draft/',
    GET_DRAFT_BANNER: BASE_URL + 'api/v1/user/get_banner_draft',
    GET_USER_BANNER: BASE_URL + 'api/v1/user/get_banner',
    GET_SINGLE_BANNER: BASE_URL + 'api/v1/user/get_single_banner/',
    EDIT_USER_BANNER: BASE_URL + 'api/v1/user/edit_banner/',
    DELETE_USER_BANNER: BASE_URL + 'api/v1/user/delete_banner/',
    CREATE_PAYMENT_BANNER_ADS: BASE_URL + 'api/v1/user/create_payment_banner_ads',
    SAVE_USER_BANNER: BASE_URL + 'api/v1/user/save_banner_ads',

    //get car model
    GET_CAR_MAKE: BASE_URL + 'api/v1/user/get_car_model_make',
    GET_CAR_NAME: BASE_URL + 'api/v1/user/get_car_model_name',


    // version 2 api's  starts from here

    //business section
    CREATE_BUSINESS_PROFILE: BASE_URL + 'api/v2/user/create_business_profile',
    CREATE_PAYMENT_WITH_MEMBERSHIP: BASE_URL + 'api/v2/user/create_payment_business_profile_with_premium_membership',
    UPDATE_BUSINESS_PROFILE_AFTER_PAYEMNT : BASE_URL + 'api/v2/user/update_business_profile_success_payment',
    OTP_SENT_BUSINESS_PROFILE: BASE_URL + 'api/v2/user/send_otp_business_profile',
    VERIFY_BUSINESS_PROFILE_OTP : BASE_URL +'api/v2/user/verify_otp_business_profile',

    //manage business profile
    GET_BUSINESS_PROFILES_DETAILS : BASE_URL + 'api/v2/user/get_business_profiles_details',
    GET_ALL_CATEGORY: BASE_URL + 'api/v2/user/get_all_category',
    EDIT_BUSINESS_PROFILE: BASE_URL +'api/v2/user/edit_business_profile',
    EDIT_BUSINESS_LOGO: BASE_URL + 'api/v2/user/update_business_logo_image',
    EDIT_BUSINESS_COVER: BASE_URL + 'api/v2/user/update_business_banner_image',
    LIKE_UNLIKE_BUSINES :BASE_URL + 'api/v2/user/like_unlike_business_directory',
    FOLLOW_UNFOLLOW_BUSINES :BASE_URL + 'api/v2/user/follow_unfollow_business_directory',
    GET_ALL_BUSINESS_DIRECTORY: BASE_URL + 'api/v2/user/get_all_business_directory',
    GET_DETAILS_BUSINESS_DIRECTORY: BASE_URL + 'api/v2/user/get_details_business_directory',
    REPORT_BUSINESS_PROFILE : BASE_URL + 'api/v2/user/report_business_profile',
    GET_DRAFT_BUSINESS_PROFILE : BASE_URL + 'api/v2/user/get_draft_business_profiles',

    //manage group section
    CREATE_GROUP : BASE_URL + 'api/v2/user/create_group',
    GET_GROUP_DETAILS: BASE_URL + 'api/v2/user/get_group_details',
    GET_ALL_USER_GROUPS: BASE_URL + 'api/v2/user/get_all_user_group',
    CREATE_GROUP_POST:  BASE_URL + 'api/v2/user/create_group_post',
    GET_ALL_FEED_GROUPS : BASE_URL + 'api/v2/user/get_all_groups',
    GET_GROUP_POST_DETAILS : BASE_URL + 'api/v2/user/get_group_post_details',
    JOIN_OR_LEAVE_GROUP : BASE_URL + 'api/v2/user/join_or_leave_group',
    COMMENT_ON_GROUP_POST : BASE_URL + 'api/v2/user/comment_on_group_post',
    REPLY_COMMENT : BASE_URL + 'api/v2/user/reply_comment_user',
    DELETE_COMMENT_FROM_POST : BASE_URL + 'api/v2/user/delete_comment_from_post',
    LIKE_UNLIKE_GROUP_POST : BASE_URL + 'api/v2/user/like_group_post',
    DELETE_GROUP : BASE_URL + 'api/v2/user/delete_group',
    DELETE_GROUP_POST : BASE_URL + 'api/v2/user/delete_group_post',
    REPORT_GROUP_POST : BASE_URL + 'api/v2/user/report_group_post',
    FIND_JOINED_GROUPS : BASE_URL + 'api/v2/user/find_joined_groups',
    GET_USER_GROUP_DETAILS : BASE_URL + 'api/v2/user/get_user_group_details',
    EDIT_USER_GROUP: BASE_URL + 'api/v2/user/edit_user_group',

    //ask ai feature
    SAVE_THREAD: BASE_URL + 'api/v2/user/save_thread',
    GET_ALL_THREAD: BASE_URL + 'api/v2/user/get_all_thread',
    CHAT_WITH_AI : BASE_URL + 'api/v2/user/ask_ai',
    GET_CHATS_BY_THREAD : BASE_URL + 'api/v2/user/get_chats_by_thread',
    DELETE_CHAT_THREAD : BASE_URL + 'api/v2/user/delete_chat_thread',
    EDIT_CHAT_THREAD : BASE_URL + 'api/v2/user/edit_chat_thread',
    GET_CHAT_LIMIT : BASE_URL + 'api/v2/user/get_chat_limit',
    CREATE_PAYMENT_FOR_LIMIT : BASE_URL + 'api/v2/user/create_payment_purchased_limit',
    PURCHASED_LIMIT: BASE_URL + 'api/v2/user/purchase_limits',
    GET_USER_LIMITS: BASE_URL + 'api/v2/user/get_user_limits',

    //pricing
    GET_ALL_PRICING: BASE_URL + 'api/v2/user/get_all_pricing'
}

export default API
export {BASE_URL}

import { createSlice } from '@reduxjs/toolkit';



export const switchSlice = createSlice({
  name: 'switch',
  initialState: {SwitchTo:'user'},
  reducers: {
      setSwitch: (state, action) => {
        state.SwitchTo = action.payload;
      },
  },
})



export const { setSwitch } = switchSlice.actions

export default switchSlice.reducer
import React from 'react'
import toast from 'react-hot-toast';
import { Navigate, Outlet, useLocation } from 'react-router-dom'

const BusinessCheck = () => {
    const loc = useLocation();
    if (loc.state === 'create-business-profile' || loc.state === 'renew-business-profile') {
        return <Outlet />;
    } else {
        return <Navigate to="/" />;
    }
}

export default BusinessCheck
// const userDetail = localStorage.getItem('businessProfile')
import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { stripePromise } from '../PromiseKey';
import CheckoutLimit from './CheckoutLimit';


const LimitPayment = () => {
  let clientSecret = localStorage.getItem('chat_limits')


  const options = {
    // passing the client secret obtained in step 3
    clientSecret: clientSecret,
    // Fully customizable with appearance API.
    appearance: {/*...*/ },

  };


  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutLimit />
    </Elements>
  )
}

export default LimitPayment

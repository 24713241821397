import { createSlice } from '@reduxjs/toolkit';



export const pricingSlice = createSlice({
  name: 'pricing',
  initialState: {Pricing:{}},
  reducers: {
      setPricing: (state, action) => {
        state.Pricing = action.payload;
      },
  },
})



export const { setPricing } = pricingSlice.actions

export default pricingSlice.reducer
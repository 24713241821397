import { useStripe } from '@stripe/react-stripe-js';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { postRequest } from '../../ApiFunction';
import { NavLink, useNavigate } from 'react-router-dom';
import { setActive } from '../../redux/slice/activeSlice';
import { useDispatch } from 'react-redux';
import API from '../../Api';


const LimitStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState('');
  const [cases, setCase] = useState();
  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Success! Payment received.');
          setCase(1);
          break;
        case 'processing':
          setMessage("Payment processing. We will update you when payment is received.");
          setCase(2);
          break;
        case 'requires_payment_method':
          setMessage('Payment failed. Please try another payment method.');
          break;
        default:
          setMessage('Something went wrong.');
          setCase(3);
          break;
      }
      const payments = {
        isChatLimit: paymentIntent.status === 'succeeded' ? true : false,
        amount: paymentIntent.amount / 100,
        paymentId: paymentIntent.id,
        status: paymentIntent.status === 'succeeded' ? true : false,
        paymentStatus: paymentIntent.status === 'succeeded' ? true : false,
      }

      if (paymentIntent.status === 'succeeded') {
        processPayment(payments); // Call the processPayment function with the updatedObj
      } else {
        localStorage.removeItem('chat_limits');
        nav('/community', { state: 'ask-our-ai' })
      }
    });

    const processPayment = async (paymentObj) => {
      try {
        let purchased = JSON.parse(localStorage.getItem('limits'))
        let obj = { purchased:purchased, ...paymentObj }
        const result = await postRequest(`${API.PURCHASED_LIMIT}`, obj);
        if (!result.data.status) {
          if (result.data.code === 401) {
            localStorage.clear();
            toast.error("Session expired. Please login again.");
            nav('/sign-in-account');
          }
          nav('/community', { state: 'feeds' })
          toast.error(result.data.message,{duration:2000})
        } else {
          toast.success(result.data.message);
          localStorage.removeItem('chat_limits');
        }
      } catch (err) {
        console.error(err.message);
      }
    };

  }, [stripe, nav]);

  const nextStep = () => {
    localStorage.removeItem('chat_limits')
    nav('/community', { state: 'ask-our-ai' })
    dispatch(setActive(true))
  }

  return (
    <div>
      {cases === 1 ?
        <>
          <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: "90vh" }}>
            <div className='article-title' style={{ textAlign: 'center' }}><h2 style={{ fontSize: '25px' }}>Payment successfully done </h2>
              <div className='topic-heading'><h5 style={{ fontSize: '14px' }}>Thank you for your payment! We are currently processing your payment details.</h5></div>
            </div>
            <img src="/image/success.png" style={{ width: "80%" }} alt="paymentImage" />
            <div className='topic-heading'><h5 style={{ fontWeight: "500", fontFamily: "arial" }}>Please click "Next"</h5></div>
            <NavLink to="/community" state="ask-our-ai">
              <button type="submit" className='nav-button' style={{ marginTop: '10px', marginBottom: '10px' }} onClick={nextStep}>
                Next
              </button>
            </NavLink>
          </div>
        </>
        :
        cases === 2 || cases === 3 ?
          <>
            <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
              <div className='article-title' style={{ textAlign: 'center' }}><h2>{message}</h2>
              </div>
              <img src="/image/errorPayment.png" style={{ width: "23%" }} alt="paymentImage" />
            </div>
          </>
          : null}
    </div>
  )
}

export default LimitStatus
import React from 'react'
import LimitStatus from './LimitStatus';
import { stripePromise } from '../PromiseKey';
import { Elements } from '@stripe/react-stripe-js';


const Limit = () => {




  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div>
        <Elements stripe={stripePromise} >
          <LimitStatus />
        </Elements>
      </div>
    </div>
  )
}

export default Limit

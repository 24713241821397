import { createSlice } from '@reduxjs/toolkit';



export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {loadingPage:false},
  reducers: {
      setLoading: (state, action) => {
        state.loadingPage = action.payload;
      },
  },
})



export const { setLoading } = loadingSlice.actions

export default loadingSlice.reducer
import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'

const GroupCheck = () => {
    const premiumData = useSelector(state => state.premium.Premium);


    return (
        <>
            {
                premiumData && premiumData.isExpired
                    ?
                    <Navigate to='/plans'></Navigate>
                    :
                    <Outlet />
            }
        </>

    )
}

export default GroupCheck
import React, { Suspense, lazy, useState, } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, } from "react-router-dom";
import LoggedIn from "./guards/LoggedIn";
import Private from "./guards/Private";
import { useSelector } from "react-redux";
import Loading from "./components/Loading";
import HomeLazy from "./components/HomeLazy";
import { Toaster } from "react-hot-toast";
import Plans from "./Pages/Plans";
import PageNotFound from "./Pages/PageNotFound";
import BusinessCheck from "./guards/BusinessCheck";
import GroupCheck from "./guards/GroupCheck";
import StatusPage from "./guards/StatusPage";
import PurchaseLimits from "./Pages/PurchaseLimit/PurchaseLimits";
import Limit from "./Pages/PurchaseLimit/Limit";

const Home = lazy(() => import('./Pages/Home/Home'));
const About = lazy(() => import('./Pages/About'));
const Search = lazy(() => import('./Pages/Home/Search'));
const Detail = lazy(() => import('./Pages/Home/Detail'));
const SignIN = lazy(() => import('./Pages/SignIN'));
const Forgot = lazy(() => import('./Pages/Forgot'));
const SignUP = lazy(() => import('./Pages/SignUP'));
const Contact = lazy(() => import('./Pages/Contact'));
const NavBar = lazy(() => import('./components/NavBar'));
const Footer = lazy(() => import('./components/Footer'));
const Community = lazy(() => import('./Pages/Community/Community'));
const TopicRe = lazy(() => import('./Pages/Home/TopicRe'));
const Privacy = lazy(() => import('./Pages/Content/Privacy'));
const Terms = lazy(() => import('./Pages/Content/Terms'));
const SearchResult = lazy(() => import('./Pages/Home/SearchResult'));
const Faq = lazy(() => import('./Pages/Content/Faq'));
const Notification = lazy(() => import('./Pages/Home/Notification'));
const Membership = lazy(() => import('./Pages/PaymentSystem/Membership'));
const MembershipPayment = lazy(() => import('./Pages/PaymentSystem/MembershipPayment'));
const Featured = lazy(() => import('./Pages/FeaturedSystem/Featured'));
const UserBanners = lazy(() => import('./Pages/BannerSystem/UserBanners'));
const PostDetail = lazy(() => import('./Pages/Community/PostDetail'));
const UserProfile = lazy(() => import('./Pages/Community/UserProfile'));
const CreateBusiness = lazy(() => import('./Pages/CreateBusiness'));
const UserBusiness = lazy(() => import('./Pages/BusinessSystem/UserBusiness'));
const DirectoryDetails = lazy(() => import('./Pages/Community/DirectoryDetails'));
const CreateGroup = lazy(() => import('./Pages/CreateGroup'));
const GroupPostDetail = lazy(() => import('./Pages/Community/GroupPostDetail'));
const GroupDetail = lazy(() => import('./Pages/Community/GroupDetail'));
const CreateGroupPost = lazy(() => import('./Pages/CreateGroupPost'));
const ChatAI = lazy(() => import('./Pages/ChatAI'));






function App() {
  const load = useSelector(state => state.loading.loadingPage);

  return (
    <>
      {load ?
        <Loading />
        :
        <>
          <BrowserRouter>
            <Suspense fallback={<HomeLazy />}>
              <NavBar />
              <Routes>
                {/* test pages */}
                <Route path="/create-business" element={<CreateBusiness />} />
                {/* ******* */}
                <Route path='/' element={<Home />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/search" element={<Search />} />
                <Route path="/privacy-policy" element={<Privacy />} />
                <Route path="/terms-&-condition" element={<Terms />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/notifications" element={<Notification />} />
                <Route path="/search-result" element={<SearchResult />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/related/:subTag" element={<TopicRe />} />
                <Route path="/details/:permaLink" element={<Detail />} />
                <Route path="*" element={<PageNotFound />} />
                <Route path="/plans" element={<Plans />} />
                <Route element={<LoggedIn />}>
                  <Route path="/sign-in-account" element={<SignIN />} />
                  <Route path="/forgot-password" element={<Forgot />} />
                  <Route path="/sign-up-account" element={<SignUP />} />
                </Route>
                <Route element={<Private />} >
                  <Route path="/payment" element={<MembershipPayment />} />
                  <Route path="/user/:name/:id" element={<UserProfile />} />
                  <Route path="/notifications" element={<Notification />} />
                  <Route element={<BusinessCheck />}>
                    <Route path="/create-business-profile" element={<CreateBusiness />} />
                  </Route>
                  <Route element={<GroupCheck />}>
                    <Route path="/create-group" element={<CreateGroup />} />
                    <Route path="/create-group-post/:id" element={<CreateGroupPost />} />
                    <Route path="/group-post-details/:name/:id" element={<GroupPostDetail />} />
                    <Route path="/group-details/:name/:id" element={<GroupDetail />} />
                  </Route>
                  <Route path="/community" element={<Community />} />
                  <Route path="/directory-details/:name/:id" element={<DirectoryDetails />} />
                  <Route path="/post-details/:permaLink" element={<PostDetail />} />
                    <Route path="/purchase-more-limits" element={<PurchaseLimits />} />
                  <Route element={<StatusPage />}>
                    <Route path="/membership-status" element={<Membership />} />
                    <Route path="/featured-status" element={<Featured />} />
                    <Route path="/banner-status" element={<UserBanners />} />
                    <Route path="/business-status" element={<UserBusiness />} />
                    <Route path="/limit-status" element={<Limit />} />
                  </Route>

                </Route>
              </Routes>
              <Footer />
            </Suspense>
          </BrowserRouter>
          <Toaster
            toastOptions={{
              duration: 1000,
              className: '',
              style: {
                border: '1px solid lightgrey',
                padding: '14px',
                width: "100%",
                fontWeight: "600",
                fontSize: "13px",
                textAlign: 'center'
              },
            }}
          />
        </>}
    </>
  );
}
export default App;

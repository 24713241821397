import { createSlice } from '@reduxjs/toolkit';



export const searchSlice = createSlice({
  name: 'search',
  initialState: {Search:""},
  reducers: {
      setSearch: (state, action) => {
        state.Search = action.payload;
      },
  },
})



export const { setSearch } = searchSlice.actions

export default searchSlice.reducer
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const LoggedIn = () => {
    var auth = localStorage.getItem("token")
  return (
    <>
    {
      !auth
        ?
        <Outlet />
        :
        <Navigate to='/'></Navigate>
    }
    </>
    
  )
}

export default LoggedIn
import React, { useEffect, useState } from 'react'
import LimitPayment from './LimitPayment';
import { getRequest, postRequest } from '../../ApiFunction';
import API from '../../Api';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import BeatLoader from 'react-spinners/BeatLoader';

const PurchaseLimits = () => {
    const [data, setData] = useState([]);
    const [query, setQuery] = useState(0);
    const [price, setPrice] = useState(0);
    const [payment, setPayment] = useState(false)
    const [load, setLoad] = useState(false)
    const [data2, setData2] = useState({})
    const nav = useNavigate()

    const handleIncrease = () => {
        setData([...data, { purchased_limits: query }]);
        setPrice(prevPrice => parseFloat((prevPrice + data2.price).toFixed(2)));
        setQuery(prevQuery => prevQuery + data2.limits);
    };

    const cancelBtn = () => {
        nav('/community', { state: "ask-our-ai" })
    }
    const handleDecrease = () => {
        if (data.length > 0) {
            const newData = [...data.slice(0, data.length - 1)];
            setData(newData);
            setPrice(prevPrice => parseFloat((prevPrice - data2.price).toFixed(2)));
            setQuery(prevQuery => prevQuery - data2.limits);
        }
    };

    const handleConfirmPayment = async () => {
        try {
            if (price / JSON.parse(localStorage.getItem('limits')).length === data2.price) {
                setLoad(true)
                const result = await postRequest(`${API.CREATE_PAYMENT_FOR_LIMIT}`, { amount: price.toString().replace('.', '') })
                if (!result.data.status) {
                    if (result.data.code === 401) {
                        localStorage.clear()
                        nav('/sign-in-account')
                    }
                    toast.error(result.data.message)
                } else {
                    localStorage.setItem('chat_limits', result.data.client_secret)
                    setPayment(true)
                    setLoad(false)
                }
            } else {
                toast.error('There is some technical issue. Please try again later.', { duration: 2000 })
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    const getPricing = async () => {
        try {
            const result = await getRequest(`${API.GET_ALL_PRICING}`);
            if (!result.data.status) {
                if (result.data.code === 401) {
                    localStorage.clear();
                    toast.error("Session expired")
                    nav("/sign-in-account")
                }
                toast.error(result.data.message)
            } else {
                setData2(result.data.data[0])
                setPrice(result.data.data[0].price)
                setQuery(result.data.data[0].limits)
                setData([{ purchased_limits: result.data.data[0].limits }])
            }
        } catch (err) {
            console.error(err.message)
        }
    };

    useEffect(() => {
        getPricing()
    }, [])
    useEffect(() => {
        localStorage.setItem('limits', JSON.stringify(data))
    }, [data])

    return (

        <div className='container'>
            <div className='purchase-limit-page'>
                {load ?
                    <div className='d-flex justify-content-center gap-2 align-items-center flex-column chats-content mt-4'>
                        <img src='/image/chat_limits_expired.jpg' alt="chats" className='chat_expired' />
                        <div className='heading'>Making payment...</div>
                        <div className="para">Please wait for a few minutes while we fetch the details of your limits.</div>
                        <BeatLoader
                            color="#8a00b7"
                            height={2}
                            speedMultiplier={1}
                            width={200}
                        />
                    </div>
                    :
                    <>
                        {payment ?
                            <LimitPayment />
                            :
                            <>
                                <div className='heading'>
                                    Purchase Queries
                                </div>
                                <div className='items mt-3'>
                                    <div className='d-flex gap-4 justify-content-center align-items-start flex-wrap'>
                                        <img src='/image/chat_limits_expired.jpg' alt="chats" className='chat_expired' />
                                        <div className='content'>
                                            <div className='heading'>ASK AI Queries</div>
                                            <div className='benefits'>You can purchase {data2.limits} ASK AI queries.</div>
                                            <div className='count'>{data2.limits} Queries</div>
                                        </div>
                                    </div>
                                    <div className='action-group'>
                                        <button className="action-button decrease" disabled={data.length === 1 ? true : false} onClick={handleDecrease}>-</button>
                                        <button className="action-button">{data.length}</button>
                                        <button className="action-button increase" onClick={handleIncrease}>+</button>
                                    </div>
                                </div>
                                <div className='heading w-100'>
                                    Summary
                                    <div className='post-hr' />
                                    <div className='purchased-summary'>
                                        <div className='heading d-flex flex-column'>
                                            ASK AI queries
                                            <div className='queries'>{data2.limits} Queries</div>
                                        </div>
                                        <div className='total'>${data2.price}</div>
                                    </div>
                                    <div className='sub-total mt-4'>
                                        <div className='d-flex justify-content-between'>
                                            <div className='para'>Price</div>
                                            <div className='money'>${price}</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='para'>Item(s)</div>
                                            <div className='money'>{data.length} x ${price}</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='para'>Queries</div>
                                            <div className='money'>{query}</div>
                                        </div>
                                        <div className='d-flex justify-content-between'>
                                            <div className='para'>Total</div>
                                            <div className='money'>${price}</div>
                                        </div>
                                    </div>
                                    <div className='w-100 text-center mt-4'>
                                        <div className='d-flex justify-content-end gap-3'>
                                            <button className='joinFollowBtn' style={{ fontSize: "14px", backgroundColor: 'white', color: "#6A6AE2", border: "2px solid #6A6AE2" }} onClick={cancelBtn}>Cancel</button>
                                            <button className='joinFollowBtn' onClick={handleConfirmPayment} style={{ fontSize: "14px" }}>Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </>}</>}

            </div>
        </div>
    )
}

export default PurchaseLimits

import React, { useEffect } from 'react'

const Plans = () => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    return (
        <div className='container'>
            <div className='plans-page'>
                <div className='plans-card1'>
                    <div className='heading'> Freemium User  </div>
                    <div className='para mt-2'>Everything you need to get started</div>
                    <div className='post-hr'></div>
                    <div className='heading mt-4'> Free</div>
                    <div className='text-left w-100 desc'>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Post 3 questions monthly at no cost, seeking insights and engaging with our platform</div>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Unlock ASK AI, ask 3 queries, gain insightful answers, make informed decisions for your enhanced profile.</div>
                    </div>
                </div>
                <div className='plans-card2' style={{ border: "1px ridge rgba(68, 68, 68, 0.2)" }}>
                    <div className='heading'> Premium User </div>
                    <div className='para mt-2'>Dive into the epitome of service excellence with our premium plan</div>
                    <div className='post-hr'></div>
                    <div className='heading mt-4' style={{ color: "#6A6AE2" }}> $4.99 USD/mo</div>
                    <div className='text-left w-100 desc'>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Unlimited questions for valuable insights. No restrictions—get the answers you need for informed decision-making.</div>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Unlock ASK AI's power: 20 queries for insightful answers, enabling informed decisions for your profile.</div>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Optimize business outcomes with our group feature, enhancing collaboration and communication within your network.</div>
                    </div>
                </div>
                <div className='plans-card1'>
                    <div className='heading'> Business User </div>
                    <div className='para mt-2'>Upgrade now to the premium tier and discover a real time of exclusive advantages</div>
                    <div className='post-hr'></div>
                    <div className='heading mt-4'> $29.99 USD/mo</div>
                    <div className='text-left w-100 desc'>
                        <div className='planSet d-flex gap-2 align-items-start d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Elevate your business with a single Business profile. Stand out at the top, gaining visibility and popularity.</div>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Exclusive Leads: Premium subscribers receive exclusive access to customer inquiries or service requests in their area.</div>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Verified Business Badge: A badge indicating that the business is a verified premium subscriber, adding credibility and trust for users seeking services.</div>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Advanced Analytics: In-depth analytics on customer reviews, allowing businesses to gather valuable feedback and improve their services. Review Responses: The ability to respond to customer reviews directly on the platform.</div>
                        <div className='planSet d-flex gap-2 align-items-start' style={{ color: "black" }}><img src="/image/Tick.svg" alt='tick' />Dedicated Customer Support: Priority Support: Access to a dedicated customer support team to address inquiries or technical issues promptly.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans

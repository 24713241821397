import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import detailReducer from "./slice/userSlice";
import searchReducer from "./slice/searchSlice";
import activeReducer from "./slice/activeSlice";
import notifyReducer from "./slice/notifySlice"
import premiumReducer from "./slice/premiumSlice";
import switchReducer from "./slice/switchSlice";
import businessReducer from "./slice/businessSlice";
import loadingReducer from "./slice/loadSlice";
import pricingReducer from "./slice/pricingSlice"

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: {
    details: detailReducer,
    search: searchReducer,
    active: activeReducer,
    loading: loadingReducer,
    switch: switchReducer,
    notifica: notifyReducer,
    premium: premiumReducer,
    business: businessReducer,
    pricing: pricingReducer
  },
  middleware: customizedMiddleware,
});

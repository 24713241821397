import React from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'

const StatusPage = () => {
    

    return (
        <>
            {
                localStorage.getItem('business_id') || 
                localStorage.getItem('featured_post') ||
                localStorage.getItem('membership_id') ||
                localStorage.getItem('chat_limits')
                    ?
                    <Outlet />
                    :
                    <Navigate to='/'></Navigate>
            }
        </>

    )
}

export default StatusPage